// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_content_delivery_bucket": "uncanny-apps-20200124213704-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d1zv0ccqdu8x9b.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-2:7ed7313f-3c91-4094-bf7d-f1b62555b943",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_UKcMzfWBx",
    "aws_user_pools_web_client_id": "1q7t1jm9ui3ambcavnkuhq17te",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "feedback",
            "endpoint": "https://iohmrn91ih.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
