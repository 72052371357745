import React from 'react';
import { Box, Heading, Text, Anchor } from 'grommet';
import { Link } from 'react-router-dom';

export const ThankYou = props => {
    return (
        <Box pad="large" align="center">
            <Box align="center">
                <Heading size="medium">Thank you</Heading>
            </Box>
            <Text>We appreciate your feedback.</Text>
            <Link style={{ marginTop: '2rem' }} to="/">
                <Anchor as="span">
                    Home
                </Anchor>
            </Link>
        </Box>
    );
};
