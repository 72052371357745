const theme = {
    global: {
        colors: {
            brand: '#444444',
            background: '#FFFFFF',
            focus: '#6FFFB0'
        },
        font: {
            family: 'Roboto',
            size: '18px',
            height: '20px'
        }
    },
    grommet: {
        extend: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }
    }
};

export default theme;