
export default {
    siteName: "Uncanny Apps",
    tagLine: "An app development company specializing in indie apps.",
    apps: [
        {
            icon: require('./Assets/BMRCalculator.jpg'),
            shortTitle: "BMR Calculator",
            title: "BMR Calculator",
            subtitle: "",
            description: "Calculate your body's BMR (Basal Metabolic Rate) to see the amount of calories you should consume every day.  This simple, easy-to-use interface calculates your BMR.  What is BMR?  That’s simple, your basal metabolic rate is an estimation of how many calories your body would burn while at rest for a full day.  In this app, we guide you through entering your height, weight, age, and gender to provide you with your BMR.  In the last step of this calculator, you can see how different activity levels and your BMR affect the amount of calories you should consume every day.  To make these calculations, we use the standard Harris Benedict Equation.",
            appStoreId: "1300425432"
        },
        {
            icon: require('./Assets/H2O_AppIcon.png'),
            shortTitle: "H2O",
            title: "H2O - Water Tracking",
            subtitle: "Drink Water and Feel Better",
            description: "A minimalistic water tracker. Record whenever you take a drink of water and get a clear picture of how much water you’re drinking every day. Synchronize with HealthKit so your data is always backed up. When you drink more water, you feel better.",
            appStoreId: "1493661410"
        },
    ]
}