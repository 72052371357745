import React from 'react';
import {
    Box,
    Form,
    FormField,
    Select,
    TextArea,
    Button,
    Heading
} from 'grommet';
import { API, Auth } from 'aws-amplify';
import { Redirect } from 'react-router-dom';
import appContent from '../../Content';

export const ContactUs = props => {
    const [feedbackType, setFeedbackType] = React.useState('Feedback');
    const [responseSubmitted, setResponseSubmitted] = React.useState(false);
    const [appName, setAppName] = React.useState(appContent.apps[0].shortTitle);
    const [message, setMessage] = React.useState('');
    const placeholder = {
        Feedback: 'Give us some feedback',
        'Report a Bug': "What's wrong?",
        'Feature Request': 'What new feature would you like to see?'
    };

    const submitFeedback = async (object) => {
        API.post('feedback', '/feedback', {
            body: {
                ...object
            }
        })
            .then(response => {
                console.log('Successful Response: ', response);
            })
            .catch(error => {
                console.log('Error: ', error);
            })
            .finally(() => {
                setResponseSubmitted(true);
            });
    };

    if (responseSubmitted) return <Redirect to="/thank-you" />;

    return (
        <Box width="100%">
            <Box pad="large">
                <Form
                    onSubmit={event => {
                        submitFeedback(event.value);
                    }}
                    value={{
                        feedbackType,
                        appName,
                        message
                    }}
                >
                    <Heading level="3">Contact Us</Heading>
                    <Box fill direction="column" gap="medium">
                        <FormField name="feedbackType" label="Type">
                            <Select
                                options={[
                                    'Feedback',
                                    'Report a Bug',
                                    'Feature Request'
                                ]}
                                value={feedbackType}
                                onChange={({ option }) =>
                                    setFeedbackType(option)
                                }
                            />
                        </FormField>
                        <FormField name="appName" label="App Name">
                            <Select
                                options={appContent.apps.map(app => app.shortTitle)}
                                value={appName}
                                onChange={({ option }) => setAppName(option)}
                            />
                        </FormField>
                        <FormField name="message" label="Description">
                            <TextArea
                                placeholder={placeholder[feedbackType]}
                                value={message}
                                onChange={event =>
                                    setMessage(event.target.value)
                                }
                            />
                        </FormField>

                        <Box align="end">
                            <Button type="submit" primary label="Submit" />
                        </Box>
                    </Box>
                </Form>
            </Box>
        </Box>
    );
};
