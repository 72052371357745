import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { Grommet, Box } from 'grommet';
import { Header } from './Components/Header';
import './index.css';
import Routes from './Routes';
import { AppFooter } from './Components/Footer';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import theme from './theme';

Amplify.configure({
    ...awsconfig,
    // API: {
    //     endpoints: [
    //         {
    //             name: "feedback",
    //             endpoint: "https://lambda.us-east-2.amazonaws.com/",
    //             service: "lambda",
    //             region: "us-east-2"
    //         }
    //     ]
    // }
});

ReactDOM.render(
    <Router onUpdate={() => window.scrollTo(0, 0)}>
        <Grommet theme={theme}>
            <Box className='main-wrapper'>
                <Header />
                <Routes />
            </Box>
            <AppFooter className='footer' />
        </Grommet>
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
