import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Heading, Paragraph, Image, ResponsiveContext, Anchor } from 'grommet';
import './index.css';
import DownloadOnAppStore from '../../Assets/App-Store-Badge/Download_on_the_App_Store_Badge_US.svg';

export const AppBox = (props = {}) => {
    const size = React.useContext(ResponsiveContext);
    const small = size === 'small';
    const {
        icon,
        title,
        shortTitle,
        subtitle,
        description,
        appStoreId,
        className = '',
        style = {}
    } = props;
    const boxProps = {
        direction: small ? 'column' : 'row',
        align: small ? 'center' : 'start'
    };
    return (
        <Box className={className} style={style} {...boxProps} width="80%" pad="medium">
            <Box className="image-wrapper">
                <Image fit="cover" src={icon} />
            </Box>
            <Box direction="column" align="start" pad="medium" width="100%">
                <Heading level="3" margin="4px 0">{title}</Heading>
                <Heading level="5" margin="4px 0">{subtitle}</Heading>
                <Paragraph fill>{description}</Paragraph>
                <Anchor href={`https://apps.apple.com/app/apple-store/id${appStoreId}`} target="_blank">
                    <Image src={DownloadOnAppStore}></Image>
                </Anchor>
                <Box direction="row" align="end" justify="end" margin={{ left: "auto" }}>
                    <Link to={`/${shortTitle.replace(' ', '-').toLowerCase()}/privacy-policy`}>
                        <Anchor as="span" size="xsmall" margin="0 16px 0 0">Privacy Policy</Anchor>
                    </Link>
                    <Link to={`/${shortTitle.replace(' ', '-').toLowerCase()}/terms-of-service`}>
                        <Anchor as="span" size="xsmall">Terms</Anchor>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};
