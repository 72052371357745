import React from 'react';
import { Footer, Text, Anchor } from 'grommet';
import { Link } from 'react-router-dom';

export const AppFooter = (props = {}) => {
    const { className } = props
    return (
        <Footer className={className} background="clear" justify="end" pad="medium">
            <Link to='/privacy-policy'>
                <Anchor as="span" size="xsmall">Privacy Policy</Anchor>
            </Link>
            <Link to='/terms-of-service'>
                <Anchor as="span" size="xsmall">Terms of Service</Anchor>
            </Link>
            <Text as="span" size="xsmall">Copyright © 2020</Text>
        </Footer>
    );
};