import React from 'react';
import { Box, Heading, Paragraph, ResponsiveContext } from 'grommet';
import { AppBox } from '../AppBox';
import appContent from '../../Content';

export const Home = (props = {}) => {
    const size = React.useContext(ResponsiveContext);
    const small = size === 'small';
    return (
        <Box flex direction="column" height="100%" width="100%" align="center">
            <Box align="center" style={{ height: `calc(100vh - var(--header-height))` }}>
                <Heading size="xlarge">{appContent.siteName}</Heading>
                <Paragraph style={small ? { padding: '0 8px' } : {}} textAlign="center" size="large">
                    {appContent.tagLine}
                </Paragraph>
            </Box>
            {Array.isArray(appContent.apps)
                ? appContent.apps.map((app, index) => (
                    <AppBox
                        key={`app-box#${index}`}
                        style={index === 0 ? { paddingTop: 0 } : {}}
                        {...app}
                    />
                ))
                : []}
        </Box>
    );
};
