import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Home } from './Components/Home';
import { ContactUs } from './Components/Contact';
import { PrivacyPolicy } from './Components/Legal/PrivacyPolicy';
import { TermsOfService } from './Components/Legal/TermsOfService';
import { ThankYou } from './Components/ThankYou';

function Routes(props = {}) {
    return (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route exact path="/contact-us">
                <ContactUs />
            </Route>
            <Route exact path="/bmr-calculator/privacy-policy">
                <PrivacyPolicy appName="bmr-calculator" />
            </Route>
            <Route exact path="/bmr-calculator/terms-of-service">
                <TermsOfService appName="bmr-calculator" />
            </Route>
            <Route exact path="/h2o/privacy-policy">
                <PrivacyPolicy appName="h2o" />
            </Route>
            <Route exact path="/h2o/terms-of-service">
                <TermsOfService appName="h2o" />
            </Route>
            <Route exact path="/privacy-policy">
                <PrivacyPolicy />
            </Route>
            <Route exact path="/terms-of-service">
                <TermsOfService />
            </Route>
            <Route exact path="/thank-you">
                <ThankYou />
            </Route>
        </Switch>
    );
}

export default Routes;
