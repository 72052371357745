import React from 'react';
import {Box, Heading, Text} from 'grommet';

function privacyPolicy(appName) {
    if (!appName) return require('../../Assets/privacy-policy.json');
    return require(`../../Assets/${appName}/privacy-policy.json`);
}

export const PrivacyPolicy = (props) => {
    return (
        <Box>
            <Box pad='large'>
                <Box align="center">
                    <Heading size='medium'>Privacy Policy</Heading>
                </Box>
                {privacyPolicy(props.appName)['value']
                    .split('\n')
                    .map((item, index) => <Box key={`privacy-policy-${index}`} pad="small">
                        <Text>{item}</Text>
                    </Box>)
}
            </Box>
        </Box>
    );
};