import React from 'react';
import {
    Box,
    Heading,
    Text,
} from 'grommet';

function termsOfService(appName) {
    if (!appName) return require('../../Assets/terms-of-service.json');
    return require(`../../Assets/${appName}/terms-of-service.json`);
}

export const TermsOfService = (props) => {
    return (
        <Box>
            <Box pad='large'>
                <Box align="center">
                    <Heading size='medium'>Terms of Service</Heading>
                </Box>
                {
                    termsOfService(props.appName)['value'].split('\n').map((item, index) => <Box key={`terms-service-${index}`} pad="small"><Text>{item}</Text></Box>)
                }
            </Box>
        </Box>
    );
};