import React from 'react';
import { Box, Heading, Anchor } from 'grommet';
import {
    Link
} from "react-router-dom";

const Icons = require('grommet-icons');

export const Header = (props) => (
    <Box
        tag='header'
        direction='row'
        align='center'
        justify='between'
        pad={{ left: 'medium', right: 'small', vertical: 'small' }}
        elevation='medium'
        style={{ zIndex: '1', height: `var(--header-height)` }}
        {...props}
    >
        <Heading level='3' margin='none'>
            <Link to='/'>
                <Anchor as='span'>
                    UA
                </Anchor>
            </Link>
        </Heading>
        <Box as="nav" gap="medium" direction='row'>
            <Link to='/'>
                <Anchor icon={<Icons.Home />}
                    as="span"
                    hoverIndicator />
            </Link>
            <Link to='/contact-us'>
                <Anchor icon={<Icons.Contact />}
                    as="span"
                    hoverIndicator />
            </Link>
        </Box>
    </Box >
);